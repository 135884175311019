import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

export const TAG_TYPE = {
	NEWS: 'NEWS',
	POST: 'POST',
};

export const getTags = createAction('GET_TAGS', async (type = TAG_TYPE.NEWS) => {
	const url = type === TAG_TYPE.NEWS ? 'newTags' : 'articleTags';
	const result = await wrapFetch(url, { method: 'GET' });

	if (result.message === 'Success' && result.data.data) {
		const data = {};

		const list = result.data.data.map(v => {
			data[v.id] = {
				id: v.id,
				value: v.name,
			};

			return v.id;
		});
		return { list, data };
	}
	return { list: [], data: {} };
});

export const addSelectedTag = createAction('ADD_SELECTED_TAG', value => value);

export const removeSelectedTag = createAction('REMOVE_SELECTED_TAG', value => value);

export const cleanSelectedTags = createAction('CLEAN_SELECTED_TAGS');

export const cleanTags = createAction('CLEAN_TAGS');

const reducer = {
	tags: handleActions(
		{
			GET_TAGS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TAGS_FULFILLED: (state, action) => ({
				...state,
				data: {
					...state.data,
					...action.payload.data,
				},
				list: action.payload.list,
				loading: false,
			}),

			ADD_SELECTED_TAG: (state, action) => ({
				...state,
				selectedTags: [...state.selectedTags, action.payload],
			}),

			REMOVE_SELECTED_TAG: (state, action) => ({
				...state,
				selectedTags: state.selectedTags.filter(v => v !== action.payload),
			}),

			CLEAN_SELECTED_TAGS: state => ({
				...state,
				selectedTags: [],
			}),

			CLEAN_TAGS: state => ({
				...state,
				data: {},
				list: [],
				selectedTags: [],
			}),
		},
		{
			loading: false,
			data: {},
			list: [],
			selectedTags: [],
		},
	),
};

const selectTags = createSelector(
	state => state.tags.list,
	state => state.tags.data,
	(list, data) => {
		return {
			tags: list.map(id => data[id]),
		};
	},
);

export const useTags = () =>
	useRedux(selectTags, {
		getTags,
		cleanTags,
	});

const selectSelectedTags = state => ({
	selectedTags: state.tags.selectedTags,
});

export const useSelectedTags = () =>
	useRedux(selectSelectedTags, {
		addSelectedTag,
		removeSelectedTag,
		cleanSelectedTags,
	});

export default { reducer };
