import React from 'react';
import { getPartners, cleanPartners, checkPartnerStatus } from 'models/partner';

export default {
	path: '/partner',
	components: () => [import('./component')],
	render: ([Partner]) => <Partner />,
	onEnter: async ({ store }) => {
		console.log('on Enter partner', store);
		await store.dispatch(checkPartnerStatus());
		const {
			partners: { partnerStatus },
		} = store.getState();

		if (partnerStatus === 'OFF') {
			window.location.href = '/';
		}
		await store.dispatch(cleanPartners());
		await store.dispatch(getPartners());

		console.log('on Enter Partner / end');
	},
};
