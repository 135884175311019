import { createAction, handleActions } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

const defaultRegistrationForm = {
	name: { title: '姓名', value: '', error: '' },
	sex: { title: '性別', value: '', error: '' },
	email: { title: 'Email', value: '', error: '' },
	contact_number: { title: '聯絡電話', value: '', error: '' },
	personal_terms: { title: '個資條款', value: '', error: '' },
};

export const cleanRegistrationForm = createAction('CLEAN_REGISTRATION_FORM');

export const getEventRegistrationById = createAction('GET_EVENT_REGISTRATION_BY_ID', async id => {
	const result = await wrapFetch(`event/${id}`, { method: 'GET' });

	if (result.message === 'Success' && result.data.data) {
		const questions = [...result.data.data.form.questions];
		const edmStatus = result.data.data.form.is_open_edm_status === 1;
		const updatedRegistrationForm = {
			name: { ...defaultRegistrationForm.name },
			sex: { ...defaultRegistrationForm.sex },
			email: { ...defaultRegistrationForm.email },
			contact_number: { ...defaultRegistrationForm.contact_number },
			personal_terms: { ...defaultRegistrationForm.personal_terms },
		};

		if (edmStatus) {
			updatedRegistrationForm.edm_status = {
				title: '訂閱電子報',
				value: false,
				error: '',
			};
		}

		questions.forEach(question => {
			if (question.key.startsWith('additional_question')) {
				updatedRegistrationForm[question.key] = {
					title: question.subject,
					value: '',
					error: '',
				};
			} else if (updatedRegistrationForm[question.key]) {
				updatedRegistrationForm[question.key].title = question.subject;
			}
		});
		const personalTerms = result.data.data.terms;
		const eventStatus = result.data.data.status;

		return { updatedRegistrationForm, personalTerms, eventStatus };
	}
	return { defaultRegistrationForm, personalTerms: {}, eventStatus: '' };
});

export const updateRegistrationForm = createAction(
	'UPDATE_REGISTRATION_FORM',
	({ key, value, error }) => ({
		key,
		value,
		error,
	}),
);

export const modalOpen = createAction('MODAL_OPEN', type => type);

export const submitRegistrationForm = createAction(
	'SUBMIT_REGISTRATION_FORM',
	() => async (dispatch, getState) => {
		const {
			registration: { registrationForm, personalTerms },
			routing: { pathname },
		} = getState();

		const answers = {
			name: registrationForm.name.value,
			sex: registrationForm.sex.value === 'male' ? '男' : '女',
			email: registrationForm.email.value,
			contact_number: registrationForm.contact_number.value,
		};

		Object.keys(registrationForm).forEach(key => {
			if (key.startsWith('additional_question')) {
				answers[key] = registrationForm[key].value;
			}
		});

		const eventCode = pathname.split('/')[3];

		let edmSubscribeStatus = 'NONE';
		if ('edm_status' in registrationForm) {
			edmSubscribeStatus = registrationForm.edm_status.value ? 'YES' : 'NO';
		}

		const { status, message } = await wrapFetch('event', {
			method: 'POST',
			body: JSON.stringify({
				event_code: eventCode,
				answer: answers,
				is_subscribe_edm: edmSubscribeStatus,
				terms_record_id: personalTerms.id,
			}),
		});

		if (status === 200 && message === 'Success') {
			dispatch(modalOpen('success'));
			return undefined;
		}
		if (status !== 200) {
			dispatch(modalOpen('failed'));
			throw new Error(message);
		}
		return undefined;
	},
);

const reducer = {
	registration: handleActions(
		{
			GET_EVENT_REGISTRATION_BY_ID_FULFILLED: (state, action) => ({
				...state,
				registrationForm: action.payload.updatedRegistrationForm,
				personalTerms: action.payload.personalTerms,
				eventStatus: action.payload.eventStatus,
				loading: false,
			}),

			CLEAN_REGISTRATION_FORM: state => ({
				...state,
				// 為什麼不能直接寫 defaultRegistrationForm
				registrationForm: {
					name: { title: '姓名', value: '', error: '' },
					sex: { title: '性別', value: '', error: '' },
					email: { title: 'Email', value: '', error: '' },
					contact_number: { title: '聯絡電話', value: '', error: '' },
					personal_terms: { title: '個資條款', value: '', error: '' },
				},
				personalTerms: {},
			}),

			UPDATE_REGISTRATION_FORM: (state, action) => ({
				...state,
				registrationForm: {
					...state.registrationForm,
					[action.payload.key]: {
						...state.registrationForm[action.payload.key],
						value: action.payload.value,
						error: action.payload.error,
					},
				},
			}),

			MODAL_OPEN: (state, action) => ({
				...state,
				modalOpen: action.payload,
			}),
		},
		{
			registrationForm: defaultRegistrationForm,
			personalTerms: {},
			eventStatus: '',
			modalOpen: null,
			loading: false,
		},
	),
};

const mapHooksToState = state => ({
	registrationForm: state.registration.registrationForm,
	personalTerms: state.registration.personalTerms,
	modalOpen: state.registration.modalOpen,
});

export const useRegistration = () =>
	useRedux(mapHooksToState, {
		getEventRegistrationById,
		cleanRegistrationForm,
		updateRegistrationForm,
		submitRegistrationForm,
	});

export default { reducer };
