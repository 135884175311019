import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { createContext, useContext } from 'react';
import { useRedux } from 'util/hook/redux';

import history from 'store/history';

export const routeChange = createAction('ROUTE_LOCATION_CHANGE', location => location);

export const setNewTagLink = createAction('SET_NEW_TAG_LINK', url => url);

export const clearNewTagLink = createAction('CLEAR_NEW_TAG_LINK');

const reducer = {
	routing: handleActions(
		{
			ROUTE_LOCATION_CHANGE: (state, action) => ({
				...state,
				...action.payload,
			}),

			SET_NEW_TAG_LINK: (state, action) => ({
				...state,
				newTagLink: action.payload,
			}),

			CLEAR_NEW_TAG_LINK: state => ({
				...state,
				newTagLink: '',
			}),
		},
		{
			...history.location,
			newTagLink: '',
		},
	),
};

export default { reducer };

const selectRouting = createSelector(
	state => state.routing.pathname,
	pathname => ({
		pathname,
	}),
);

const selectNewTagLink = createSelector(
	state => state.routing.newTagLink,
	newTagLink => ({
		newTagLink,
	}),
);

export const HistoryContext = createContext({
	location: { pathname: '/' },
});

export const useRouting = () => useRedux(selectRouting);

export const useNewTagLink = () => useRedux(selectNewTagLink, { setNewTagLink, clearNewTagLink });

export const useHistory = () => useContext(HistoryContext);
