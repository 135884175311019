import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

export const getBanner = createAction('GET_BANNER', async () => {
	const result = await wrapFetch('banners');
	if (result.message === 'Success' && result.data.data) {
		const data = {};

		const list = result.data.data.map(v => {
			data[v.id] = {
				pcImage: v.img_pc,
				mobileImage: v.img_mobile,
				url: v.link,
				title: v.title || '',
				content: v.subtitle || '',
				youtubeLink: v.youtube_link || '',
			};

			return v.id;
		});

		return { list, data };
	}
	return { list: [], data: {} };
});

const reducer = {
	banner: handleActions(
		{
			GET_BANNER_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_BANNER_FULFILLED: (state, action) => ({
				...state,
				data: {
					...state.data,
					...action.payload.data,
				},
				list: action.payload.list,
				loading: false,
			}),
		},
		{
			loading: false,
			data: {},
			list: [],
		},
	),
};

const selectBanner = createSelector(
	state => state.banner.list,
	state => state.banner.data,
	(list, data) => ({
		banner: list.map(id => data[id]),
	}),
);

export const useBanner = () => useRedux(selectBanner, { getBanner });
export default { reducer };
