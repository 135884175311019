import React from 'react';

import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import LinkAlert from 'components/molecules/LinkAlert';
import Toast from 'components/atoms/Toast';
import SubscribeHeader from 'components/organisms/SubscribeHeader';

import { useRouting, useNewTagLink } from 'models/routing';

import { isExist } from 'util/helper';

const App = ({ children }) => {
	const [{ pathname }] = useRouting();
	const [{ newTagLink }] = useNewTagLink();
	const ignoreFooter = [].includes(pathname);
	const ignoreFooterHeader = ['/subscribe-success'].includes(pathname);

	return (
		<>
			{ignoreFooterHeader && <SubscribeHeader />}
			{!ignoreFooterHeader && <Header />}
			{children}
			{!ignoreFooter && !ignoreFooterHeader && <Footer />}
			<Toast />
			{isExist(newTagLink) && <LinkAlert />}
		</>
	);
};

export default App;
