import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { wrapFetch } from 'util/api';

// legalTags 有三種類別：industry, country 和 supplyChain，
// 被選擇的 tags 會依照順序排列 [industry, country, supplyChain]。default tags array 則是取每一個類別的第一個
export const getLegalMapTags = createAction('GET_LEGALMAPTAGS', async () => {
	const result = await wrapFetch('regulatoryArticleTags', { method: 'GET' });

	if (result.message === 'Success' && result.data.data) {
		const defaultSelectedTags = [
			result.data.data.industryTags[0].name,
			result.data.data.countryTags[0].name,
			result.data.data.supplyChainTags[0].name,
		];
		return { legalTags: result.data.data, defaultSelected: defaultSelectedTags };
	}
	return { legalTags: {} };
});

const updateSelectedLegalTags = createAction('UPDATE_SELECTED_LEGAL_TAGS', (type, value) => ({
	type,
	value,
}));

const getTagId = (tag, tagsCategory) => {
	const foundTag = tagsCategory.find(item => item.name === tag);
	return foundTag.id;
};

export const getLegalPosts = createAction('GET_LEGAL_POSTS', page => async (dispatch, getState) => {
	let queryPage = page;

	if (typeof queryPage === 'undefined') {
		const {
			legalPosts: { legalPostsMeta },
		} = getState();
		queryPage = legalPostsMeta.currentPage;
	}

	const {
		legalPosts: { selectedLegalTags, legalTags },
	} = getState();

	// selectedLegalTags 只有存放 tag value，但 api 需要回傳對應的 tag id
	const tagsIds = selectedLegalTags.map((tag, index) => {
		switch (index) {
			case 0:
				return getTagId(tag, legalTags.industryTags);
			case 1:
				return getTagId(tag, legalTags.countryTags);
			case 2:
				return getTagId(tag, legalTags.supplyChainTags);
			default:
				return null;
		}
	});

	const result = await wrapFetch(
		'regulatoryArticles',
		{
			method: 'GET',
		},
		{
			industry_id: tagsIds[0],
			country_id: tagsIds[1],
			supply_chain_id: tagsIds[2],
			page: queryPage,
		},
	);

	if (result.message === 'Success' && result.data.data) {
		return {
			legalPostsData: result.data.data,
			legalPostsMeta: {
				currentPage: result.data.meta.current_page,
				from: result.data.meta.from,
				lastPage: result.data.meta.last_page,
				perPage: result.data.meta.per_page,
				to: result.data.meta.to,
				total: result.data.meta.total,
			},
		};
	}
	return { legalPostsData: [], legalPostsMeta: {} };
});

const reducer = {
	legalPosts: handleActions(
		{
			GET_LEGALMAPTAGS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_LEGALMAPTAGS_FULFILLED: (state, action) => ({
				...state,
				legalTags: {
					...state.legalTags,
					...action.payload.legalTags,
				},
				selectedLegalTags: action.payload.defaultSelected || state.selectedLegalTags,
				loading: false,
			}),

			UPDATE_SELECTED_LEGAL_TAGS: (state, action) => {
				const newSelectedLegalTags = [...state.selectedLegalTags];
				switch (action.payload.type) {
					case 'industryTags':
						newSelectedLegalTags[0] = action.payload.value;
						break;
					case 'countryTags':
						newSelectedLegalTags[1] = action.payload.value;
						break;
					case 'supplyChainTags':
						newSelectedLegalTags[2] = action.payload.value;
						break;
					default:
						break;
				}
				return { ...state, selectedLegalTags: newSelectedLegalTags };
			},

			GET_LEGAL_POSTS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_LEGAL_POSTS_FULFILLED: (state, action) => ({
				...state,
				legalPostsData: [...action.payload.legalPostsData],
				legalPostsMeta: {
					...action.payload.legalPostsMeta,
				},
				loading: false,
			}),
		},
		{
			loading: false,
			legalTags: {},
			selectedLegalTags: [],
			legalPostsData: [],
			legalPostsMeta: {
				currentPage: 0,
				from: 0,
				lastPage: 0,
				perPage: 0,
				to: 0,
				total: 0,
			},
		},
	),
};

const mapHooksToState = state => ({
	legalTags: state.legalPosts.legalTags,
	selectedTags: state.legalPosts.selectedLegalTags,
	legalPosts: state.legalPosts.legalPostsData,
	legalPostsMeta: state.legalPosts.legalPostsMeta,
});

export const useLegalMap = () =>
	useRedux(mapHooksToState, { getLegalMapTags, updateSelectedLegalTags, getLegalPosts });

export default { reducer };
