import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { wrapFetch } from 'util/api';

import { useRedux } from 'util/hook/redux';

/**
 * 為了區分清單頁與內頁資料，定義變數名如下：
 * news: 最新消息列表（清單頁）
 * newsInfo: 單一筆消息（內頁）
 */

export const getNews = createAction('GET_NEWS', async ({ page, tags }) => {
	const result = await wrapFetch(
		'news',
		{
			method: 'GET',
		},
		{
			page,
			tags,
		},
	);

	if (result.message === 'Success' && result.data.data) {
		const data = {};

		const list = result.data.data.map(v => {
			data[v.id] = {
				id: v.id,
				date: v.news_date,
				title: v.title,
			};

			return v.id;
		});

		return {
			list,
			data,
			meta: {
				currentPage: result.data.meta.current_page,
				from: result.data.meta.from,
				lastPage: result.data.meta.last_page,
				perPage: result.data.meta.per_page,
				to: result.data.meta.to,
				total: result.data.meta.total,
			},
		};
	}
	return { list: [], data: {}, meta: {} };
});

export const selectNewsByTags = createAction('SELECT_NEWS_BY_TAGS', tags => tags);

export const cleanNews = createAction('CLEAN_NEWS');

export const getNewsProcess = createAction(
	'GET_NEWS_PROCESS',
	page => async (dispatch, getState) => {
		let queryPage = page;

		if (typeof queryPage === 'undefined') {
			const {
				news: { meta },
			} = getState();

			queryPage = meta.currentPage;
		}

		const {
			news: { selectCategoryTags },
		} = getState();

		await dispatch(
			getNews({
				page: queryPage,
				tags: selectCategoryTags.toString(),
			}),
		);
	},
);

const defaultNewsInfo = {
	id: -1,
	news_date: '2022/09/30',
	title: '',
	tags: [],
	related_link: '',
	news_files: [],
	news_blocks: [],
	next_id: -1,
	last_id: -1,
};

export const getNewsInfoById = createAction('GET_NEWS_INFO_BY_ID', async id => {
	const result = await wrapFetch(`new/${id}`, { method: 'GET' });

	if (result.message === 'Success' && result.data.data) {
		return { ...defaultNewsInfo, ...result.data.data };
	}
	return {};
});

export const cleanNewsInfo = createAction('CLEAN_NEWS_INFO');

const reducer = {
	news: handleActions(
		{
			GET_NEWS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_NEWS_FULFILLED: (state, action) => ({
				...state,
				data: {
					...state.data,
					...action.payload.data,
				},
				list: action.payload.list,
				meta: {
					...state.meta,
					...action.payload.meta,
				},
				loading: false,
			}),

			SELECT_NEWS_BY_TAGS: (state, action) => ({
				...state,
				selectCategoryTags: action.payload,
			}),

			GET_NEWS_INFO_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_NEWS_INFO_BY_ID_FULFILLED: (state, action) => ({
				...state,
				newsInfo: action.payload,
				loading: false,
			}),

			CLEAN_NEWS: state => ({
				...state,
				data: {},
				list: [],
				selectCategoryTags: [],
			}),

			CLEAN_NEWS_INFO: state => ({
				...state,
				newsInfo: {},
			}),
		},
		{
			loading: false,
			data: {},
			list: [],
			meta: {
				currentPage: 0,
				from: 0,
				lastPage: 0,
				perPage: 0,
				to: 0,
				total: 0,
			},
			selectCategoryTags: [],
			search: '',
			newsInfo: {},
		},
	),
};

const selectNews = createSelector(
	state => state.news.list,
	state => state.news.data,
	state => state.news.meta,
	(list, data, meta) => ({
		news: list.map(id => data[id]),
		meta,
	}),
);

export const useNews = () => useRedux(selectNews, { cleanNews });

const selectNewsFilter = createSelector(
	state => state.news.selectCategoryTags,
	list => ({
		tags: list,
	}),
);

export const useNewsFilter = () => useRedux(selectNewsFilter, { getNewsProcess, selectNewsByTags });

const selectNewsInfo = state => ({
	newsInfo: state.news.newsInfo,
});

export const useNewsInfo = () => useRedux(selectNewsInfo, { getNewsInfoById });

export default { reducer };
