import OriginRoute from './Origin';
import LocationRoute from './Location';
import ExpertRoute from './Expert';

const routes = {
	path: '/about',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [OriginRoute, LocationRoute, ExpertRoute],
};

export default routes;
