export const sleep = time => new Promise(resolve => setTimeout(() => resolve(), time));

export const range = (start, length) => Array.from(new Array(length), (_, i) => start + i);

export const isExist = value => value !== null && value !== '' && typeof value !== 'undefined';

export const isEmpty = value => !isExist(value);

export const normalizePrice = num => {
	if (num.length <= 3) {
		return num;
	}

	return `${normalizePrice(num.slice(0, -3))},${num.slice(-3)}`;
};

export const validateEmail = value => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	return emailRegex.test(value);
};

export const chunk = (str, n) => str.split(new RegExp(`(.{${n}})`)).filter(x => x.length > 0);

// https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie
export const isInternetExplorer = () =>
	window.navigator.userAgent.indexOf('MSIE ') > 0 ||
	window.navigator.userAgent.indexOf('Trident/') > 0;

const PALETTE_ARRAY = [
	'--primary-active',
	'--primary-hover',
	'--primary-default',
	'--primary-disabled',
	'--primary-light',
	'--primary-background',
];

export const hashPalette = name => PALETTE_ARRAY[name.charCodeAt(0) % 5];

export const getImageUrl = image => `${process.env.S3_ENDPOINT}/${image}`;

export const getYoutubeLink = link => {
	const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	const match = link.match(regExp);
	const id = match && match[7].length === 11 ? match[7] : false;
	return `https://www.youtube.com/embed/${id}`;
};

export const searchCSE = (value, history) => {
	history.push(`/search?q=${value}`, '_self');
	history.go(0);
};

// (https://dev.to/kevin940726/one-fun-trick-to-observe-elements-in-realtime-without-mutationobserver-22kj)
export const editAfterLoaded = (selector, callback) => {
	function queryElements(s, c) {
		const elements = document.querySelectorAll(s);
		elements.forEach(item => c(item));
	}
	queryElements(selector, callback);
	const observer = new MutationObserver(() => {
		queryElements(selector, callback);
	});
	observer.observe(document.documentElement, {
		attributes: true,
		childList: true,
		characterData: true,
		subtree: true,
	});
};
