import React from 'react';
import { getPostInfoById, cleanPostInfo } from 'models/post';

export default {
	path: '/:id',
	components: () => [import('./component')],
	render: ([Post]) => <Post />,
	onEnter: async ({ store, params }) => {
		console.log('on Enter post', store);
		await store.dispatch(cleanPostInfo());
		await store.dispatch(getPostInfoById(params.id));
		console.log('on Enter post / end');
	},
};
