import React, { useEffect } from 'react';
import classnames from 'classnames';

import { useToast } from 'models/toast';

import SuccessIcon from 'images/icon/toast-success.inline.svg';
import WarningIcon from 'images/icon/toast-error.inline.svg';
import styles from './index.css';

const Toast = () => {
	const [{ type, message }, { hideToast }] = useToast();

	useEffect(() => {
		if (type !== '') {
			setTimeout(() => {
				hideToast();
			}, 3000);
		}
	}, [type]);

	return (
		<>
			{true && (
				<div
					className={classnames(styles.toastWrapper, {
						[styles.active]: message || type,
					})}
				>
					{type === 'success' && <SuccessIcon />}
					{type === 'failed' && <WarningIcon />}
					<p>{message}</p>
				</div>
			)}
		</>
	);
};

export default Toast;
