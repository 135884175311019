import React from 'react';

import { getEventList, statusMapping, cleanEvents } from 'models/events';

import EventRoute from './Event';

export default {
	path: '/events',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([Events]) => <Events />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter events', store);

				const {
					events: { selectedEventStatus },
				} = store.getState();

				store.dispatch(cleanEvents());

				if (selectedEventStatus !== '') {
					const statusKey = Object.keys(statusMapping).find(
						key => statusMapping[key] === selectedEventStatus,
					);
					await store.dispatch(getEventList(undefined, statusKey));
				} else {
					await store.dispatch(getEventList());
				}
				const children = await next();
				console.log('on Enter events / end');

				return children;
			},
		},
		EventRoute,
	],
};
