import React from 'react';

import LegalPostRoute from './LegalPost';

export default {
	path: '/legal-posts',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([LegalPosts]) => <LegalPosts />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter legal map posts', store);

				const children = await next();
				console.log('on Enter legal map post / end');

				return children;
			},
		},
		LegalPostRoute,
	],
};
