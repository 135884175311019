import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

export const showToast = createAction('SHOW_TOAST', data => ({
	type: data.type || '',
	message: data.message || '',
}));

export const hideToast = createAction('HIDE_TOAST');

const reducer = {
	toast: handleActions(
		{
			SHOW_TOAST: (state, action) => ({
				...state,
				type: action.payload.type,
				message: action.payload.message,
			}),

			HIDE_TOAST: state => ({
				...state,
				type: '',
				message: '',
			}),
		},
		{
			type: '',
			message: '',
		},
	),
};

const mapStateToHook = state => state.toast;

export const useToast = () => useRedux(mapStateToHook, { showToast, hideToast });

export default { reducer };
