import { useEffect, useState, useRef } from 'react';

export const useMedia = () => {
	const mobileMedia = window.matchMedia('(max-width: 1023px)');
	const desktopMedia = window.matchMedia('(min-width: 1024px)');

	let defaultMedia = 'desktop';

	if (mobileMedia.matches) {
		defaultMedia = 'mobile';
	}

	const [media, setMedia] = useState(defaultMedia);

	const handleMediaChange = mediaName => mediaHandler => {
		if (mediaHandler.matches && mediaName !== media) {
			setMedia(mediaName);
		}
	};

	useEffect(() => {
		const mobileHandler = handleMediaChange('mobile');
		const desktopHandler = handleMediaChange('desktop');

		mobileMedia.addListener(mobileHandler);
		desktopMedia.addListener(desktopHandler);

		return () => {
			mobileMedia.removeListener(mobileHandler);
			desktopMedia.removeListener(desktopHandler);
		};
	}, [media]);

	return media;
};

export const useDom = eventHandler => {
	// Only subscribe/unsubscribe on mount/unmount lifecycle
	useEffect(() => {
		Object.keys(eventHandler).forEach(event => window.addEventListener(event, eventHandler[event]));

		return () => {
			Object.keys(eventHandler).forEach(event =>
				window.removeEventListener(event, eventHandler[event]),
			);
		};
	}, []);
};

export const useScroll = (onScroll = () => {}) => {
	const [scrollY, setState] = useState(window.pageYOffset);
	const preventTrigger = useRef(false);

	const handleScroll = () => {
		if (!preventTrigger.current) {
			window.requestAnimationFrame(() => {
				setState(window.pageYOffset);
				onScroll(window.pageYOffset);
				preventTrigger.current = false;
			});
		}
		preventTrigger.current = true;
	};

	useDom({ scroll: handleScroll });

	return scrollY;
};
