import { createAction, handleActions } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

export const statusMapping = {
	OPENING_SOON: '即將開放',
	IN_PROGRESS: '受理報名中',
	FULL: '已額滿',
	END: '已結束',
};

const reverseStatusMapping = Object.entries(statusMapping).reduce((acc, [key, value]) => {
	acc[value] = key;
	return acc;
});

export const getEventList = createAction('GET_EVENTS', page => async (dispatch, getState) => {
	let queryPage = page;
	const queryParams = {
		page: queryPage,
	};

	const {
		events: { eventsMeta, selectedEventStatus },
	} = getState();

	let statusData = [...selectedEventStatus];
	if (typeof queryPage === 'undefined') {
		queryPage = eventsMeta.currentPage;
	}

	if (statusData.length !== 0) {
		statusData = statusData.map(item => reverseStatusMapping[item]).join(',');
		queryParams.status = statusData;
	}

	const result = await wrapFetch(
		'event/list',
		{
			method: 'GET',
		},
		queryParams,
	);

	if (result.message === 'Success' && result.data.data) {
		const updateEventsData = result.data.data.map(event => ({
			...event,
			status: statusMapping[event.status] || event.status,
		}));
		return { eventsData: updateEventsData, eventsMeta: result.data.meta };
	}
	return { eventsData: [], eventsMeta: {} };
});

export const cleanEvents = createAction('CLEAN_EVENTS');

const defaultEventInfo = {
	id: -1,
	title: '',
	status: '',
	cover_image: '',
	banner_image: '',
	content: '',
	agenda: '',
	location: '',
	traffic: '',
	registration_fee: '',
	contact_information: '',
	attention_note: '',
	limit: 999,
	begin_at: '',
	end_at: '',
	registration_begin_at: '',
	registration_end_at: '',
	prev_code: '',
	next_code: '',
	event_code: '',
	form: { questions: [], is_open_edm_status: 1 },
};

export const getEventInfoById = createAction('GET_EVENT_BY_ID', async id => {
	const result = await wrapFetch(`event/${id}`, { method: 'GET' });

	if (result.message === 'Success' && result.data.data) {
		return {
			...defaultEventInfo,
			...result.data.data,
			event_code: id,
			status: statusMapping[result.data.data.status] || result.data.data.status,
		};
	}
	return {};
});

export const cleanEventInfo = createAction('CLEAN_EVENT');

export const selectEventStatus = createAction(
	'SELECT_EVENT_STATUS',
	status => (dispatch, getState) => {
		const {
			events: { selectedEventStatus },
		} = getState();

		const newSelectedEventStatus = [...selectedEventStatus];
		if (newSelectedEventStatus.includes(status)) {
			newSelectedEventStatus.splice(newSelectedEventStatus.indexOf(status), 1);
		} else {
			newSelectedEventStatus.push(status);
		}
		return newSelectedEventStatus;
	},
);

export const deleteEventStatus = createAction('DELETE_EVENT_STATUS', status => status);

export const cleanEventStatus = createAction('CLEAN_EVENT_STATUS');

const reducer = {
	events: handleActions(
		{
			GET_EVENTS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_EVENTS_FULFILLED: (state, action) => ({
				...state,
				eventsData: action.payload.eventsData,
				eventsMeta: { ...state.eventsMeta, ...action.payload.eventsMeta },
				loading: false,
			}),
			GET_EVENT_BY_ID_FULFILLED: (state, action) => ({
				...state,
				eventInfo: action.payload,
			}),
			CLEAN_EVENTS: state => ({
				...state,
				eventsData: [],
			}),
			CLEAN_EVENT: state => ({
				...state,
				eventInfo: {},
			}),
			SELECT_EVENT_STATUS: (state, action) => ({
				...state,
				selectedEventStatus: action.payload,
			}),
			CLEAN_EVENT_STATUS: state => ({
				...state,
				selectedEventStatus: [],
			}),
			DELETE_EVENT_STATUS: (state, action) => ({
				...state,
				selectedEventStatus: state.selectedEventStatus.filter(v => v !== action.payload),
			}),
		},
		{
			eventsData: [],
			eventsMeta: {
				currentPage: 0,
				from: 0,
				lastPage: 0,
				perPage: 0,
				to: 0,
				total: 0,
			},
			eventInfo: {},
			selectedEventStatus: [],
			loading: false,
		},
	),
};

const mapHooksToState = state => ({
	eventsData: state.events.eventsData,
	eventsMeta: state.events.eventsMeta,
	eventInfo: state.events.eventInfo,
	selectedEventStatus: state.events.selectedEventStatus,
});

export const useEvents = () =>
	useRedux(mapHooksToState, {
		getEventList,
		selectEventStatus,
		cleanEventStatus,
		deleteEventStatus,
	});

export default { reducer };
