import React from 'react';

import SubscribePolicy from 'components/molecules/SubscribePolicy';

import { useBoolean } from 'util/hook';

import Logo from 'images/icon/stli-biofuture-logo-dark.inline.svg';
// import PhoneIcon from 'images/icon/phone.inline.svg';
import EmailIcon from 'images/icon/email.inline.svg';
import AddressIcon from 'images/icon/address.inline.svg';

import styles from './index.css';

const Footer = () => {
	const [showPrivacyPolicy, { setFalse: setPrivacyPolicyClose, setTrue: setPrivacyPolicyOpen }] =
		useBoolean();

	return (
		<footer className={styles.footer}>
			<div className={styles.topSection}>
				<div className={styles.left}>
					<Logo />
					<p>財團法人資訊工業策進會︱科技法律研究所</p>
				</div>
				<ul className={styles.right}>
					{/* <li>
						<PhoneIcon />
						(02)6631-1037
					</li> */}
					<li>
						<EmailIcon />
						<a href="mailto: biotechlaw@iii.org.tw">biotechlaw@iii.org.tw</a>
					</li>
					<li>
						<AddressIcon />
						106 台北市大安區敦化南路二段216號22樓
					</li>
				</ul>
			</div>
			<div className={styles.bottomSection}>
				<div className={styles.privacyPolicy}>
					<button type="button" onClick={setPrivacyPolicyOpen}>
						隱私權條款
					</button>
					{showPrivacyPolicy && <SubscribePolicy onClose={setPrivacyPolicyClose} />}
				</div>
				<div className={styles.copyRight}>Copyright © 2022 STLI,III. All Rights Reserved.</div>
			</div>
		</footer>
	);
};

export default Footer;
