import React from 'react';
import { getNewsInfoById, cleanNewsInfo } from 'models/news';

export default {
	path: '/:id',
	components: () => [import('./component')],
	render: ([NewsInfo]) => <NewsInfo />,
	onEnter: async ({ store, params }) => {
		await store.dispatch(cleanNewsInfo());
		await store.dispatch(getNewsInfoById(params.id));
	},
};
