import { getLegalMapTags, getLegalPosts } from 'models/legalMap';

import PostsRoute from './LegalPosts';

const routes = {
	path: '/legalMap',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store }) => {
		await store.dispatch(getLegalMapTags());
		await store.dispatch(getLegalPosts());

		const children = await next();
		return children;
	},
	children: [PostsRoute],
};

export default routes;
