import React from 'react';
import { getPostsProcess, cleanPosts, selectPostsByTags } from 'models/post';
import { getTags, cleanTags, addSelectedTag, TAG_TYPE } from 'models/tag';

import PostRoute from './Post';

export default {
	path: '/posts',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([Posts]) => <Posts />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter posts', store);
				const {
					posts: { selectCategoryTags },
				} = store.getState();

				store.dispatch(cleanPosts());
				store.dispatch(cleanTags());

				if (selectCategoryTags.length !== 0) {
					store.dispatch(selectPostsByTags(selectCategoryTags));
					selectCategoryTags.forEach(tag => store.dispatch(addSelectedTag(tag)));
				}

				await store.dispatch(getPostsProcess(1));
				await store.dispatch(getTags(TAG_TYPE.POST));
				const children = await next();
				console.log('on Enter post / end');

				return children;
			},
		},
		PostRoute,
	],
};
