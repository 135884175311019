import React, { useRef } from 'react';

import Modal from 'components/molecules/Modal';
import Button, { BUTTON_SIZE_TYPE, BUTTON_KIND_TYPE } from 'components/atoms/Button';

import { useNewTagLink } from 'models/routing';

import CloseIcon from 'images/icon/close.inline.svg';

import styles from './index.css';

const LinkAlert = () => {
	const modalRef = useRef(null);
	const [{ newTagLink }, { clearNewTagLink }] = useNewTagLink();

	const close = () => {
		clearNewTagLink();
		if (modalRef.current) {
			modalRef.current.close();
		}
	};

	const onRedirect = () => {
		window.open(newTagLink, '_blank');
		close();
	};

	return (
		<Modal ref={modalRef} defaultOpened>
			<div className={styles.linkAlertWrapper}>
				<CloseIcon className={styles.closeIcon} onClick={close} />
				<h1 className={styles.title}>您即將前往外部網站</h1>
				<div className={styles.container}>
					<p className={styles.content}>
						您即將前離開 stli 生醫大未來並前往外部網站
						<span className={styles.link} href={newTagLink}>
							{newTagLink}
						</span>
						<br />
					</p>
					<p className={styles.content}>是否繼續前往？</p>
				</div>
				<div className={styles.actions}>
					<Button size={BUTTON_SIZE_TYPE.MEDIUM} kind={BUTTON_KIND_TYPE.SECONDARY} onClick={close}>
						取消
					</Button>
					<Button size={BUTTON_SIZE_TYPE.MEDIUM} onClick={onRedirect}>
						確認
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default LinkAlert;
