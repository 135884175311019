/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import classnames from 'classnames';

import SearchIcon from 'images/icon/search.inline.svg';

import { useMedia } from 'util/event';
import { isExist } from 'util/helper';

import styles from './index.css';

export const INPUT_SIZE_TYPE = {
	BASIC: 'BASIC',
	SMALL: 'SMALL',
};

export const InputSearch = ({ ...other }) => {
	const media = useMedia();
	return (
		<Input {...other} autoFocus={media !== 'desktop'} type="text" Icon={SearchIcon} isLeftIcon />
	);
};

const Input = ({
	className,
	Icon,
	isLeftIcon,
	size,
	onClickIcon = () => {},
	disabled = false,
	error = false,
	textarea = false,
	errorMessage = '',
	...other
}) => (
	<div
		className={classnames(styles.input, className, {
			[styles.icon]: isExist(Icon),
			[styles.isLeftIcon]: isExist(Icon) && isLeftIcon,
			[styles.disabled]: disabled,
			[styles.error]: error,
			[styles.small]: size === INPUT_SIZE_TYPE.SMALL,
		})}
	>
		{!textarea && <input disabled={disabled} {...other} />}
		{textarea && <textarea disabled={disabled} {...other} />}
		{isExist(Icon) && (
			<button className={styles.icon} onClick={onClickIcon} type="button">
				<Icon />
			</button>
		)}
		{errorMessage !== '' && <p className={styles.errorMessage}>{errorMessage}</p>}
	</div>
);

export default Input;
