import React from 'react';
import classnames from 'classnames';

import SearchInput from 'components/molecules/SearchInput';
import Navigation, { MobileNavigator } from 'components/molecules/Navigation';

import { useHistory, useRouting } from 'models/routing';
import { searchCSE } from 'util/helper';

import { useBoolean } from 'util/hook';
import { useScroll, useMedia } from 'util/event';

import LogoLight from 'images/icon/stli-biofuture-logo-light.inline.svg';
import LogoDark from 'images/icon/stli-biofuture-logo-dark.inline.svg';

import styles from './index.css';

const Header = () => {
	const media = useMedia();
	const scrollY = useScroll();
	const [{ pathname }] = useRouting();
	const history = useHistory();
	const [isSearchInputFocus, { setFalse: setUnFocus, setTrue: setFocus }] = useBoolean({
		defaultBoolean: false,
	});

	const atHomePage = pathname === '/';
	const notInverse = !atHomePage;
	const fixed = scrollY > 0;
	const renderLogo = media === 'desktop' || !isSearchInputFocus;

	const onClick = () => {
		if (atHomePage) {
			history.replace('/');
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
			return;
		}
		history.push('/');
	};

	return (
		<header
			className={classnames(styles.header, {
				[styles.notInverse]: fixed || notInverse,
				[styles.flexEnd]: !renderLogo,
			})}
		>
			{renderLogo && (
				<button className={styles.logo} type="button" onClick={onClick}>
					{fixed || notInverse ? <LogoDark /> : <LogoLight />}
				</button>
			)}
			{media === 'desktop' && !isSearchInputFocus && (
				<Navigation notInverse={fixed || notInverse} />
			)}
			<div className={styles.headerRight}>
				<SearchInput
					media={media}
					notInverse={fixed || notInverse}
					isFocus={isSearchInputFocus}
					onBlur={() => setTimeout(() => setUnFocus(), 100)}
					setFocus={setFocus}
					onFetchSearch={value => searchCSE(value, history)}
				/>
				{media !== 'desktop' && <MobileNavigator notInverse={fixed || notInverse} />}
			</div>
		</header>
	);
};

export default Header;
