/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import classnames from 'classnames';

import { isExist } from 'util/helper';

import styles from './index.css';

export const BUTTON_SIZE_TYPE = {
	LARGE: 'LARGE',
	MEDIUM: 'MEDIUM',
	SMALL: 'SMALL',
};

export const BUTTON_KIND_TYPE = {
	PRIMARY: 'PRIMARY',
	SECONDARY: 'SECONDARY',
	OUTLINE: 'OUTLINE',
};

export const IconButton = ({ children, className, ...props }) => (
	<Button className={classnames(styles.iconButton, className)} {...props}>
		{children}
	</Button>
);

const Button = ({
	children,
	className,
	disabled = false,
	outline = false,
	transparent = false,
	active = false,
	kind,
	onClick = () => {},
	Icon,
	size = BUTTON_SIZE_TYPE.LARGE,
	...other
}) => (
	<button
		className={classnames(styles.button, className, {
			[styles.disabled]: disabled,
			[styles.icon]: isExist(Icon),
			[styles.outline]: outline,
			[styles.transparent]: transparent,
			[styles.active]: active,
			[styles.medium]: size === BUTTON_SIZE_TYPE.MEDIUM,
			[styles.small]: size === BUTTON_SIZE_TYPE.SMALL,
			[styles.secondary]: kind === BUTTON_KIND_TYPE.SECONDARY,
			[styles.outline]: kind === BUTTON_KIND_TYPE.OUTLINE,
		})}
		type="button"
		onClick={e => {
			if (!disabled) {
				onClick(e);
			}
		}}
		{...other}
	>
		<div>
			{children}
			{Icon && (
				<Icon
					className={classnames(styles.icon, {
						[styles.medium]: size === BUTTON_SIZE_TYPE.MEDIUM,
						[styles.small]: size === BUTTON_SIZE_TYPE.SMALL,
						[styles.hasText]: isExist(children),
					})}
				/>
			)}
		</div>
	</button>
);

export default Button;
