import React from 'react';
import classnames from 'classnames';

import { isEmpty } from 'util/helper';

import ActiveIcon from 'images/icon/checkbox-active.inline.svg';

import styles from './index.css';

const Checkbox = ({
	className,
	id,
	name,
	value,
	children,
	checked,
	position = 'left',
	disabled = false,
	onChange = () => {},
}) => (
	<label
		className={classnames(styles.checkbox, className, {
			[styles.left]: position === 'left',
			[styles.right]: position === 'right',
			[styles.disabled]: disabled,
			[styles.isEmpty]: isEmpty(children),
		})}
		htmlFor={id}
	>
		<input
			type="checkbox"
			id={id}
			name={name}
			value={value}
			className={styles.icon}
			checked={checked}
			disabled={disabled}
			onChange={onChange}
		/>
		{children}
		<div className={styles.icon} />
		<ActiveIcon className={styles.iconActive} />
	</label>
);

export default Checkbox;
