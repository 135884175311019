/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

import React, { useRef } from 'react';
import classnames from 'classnames';

import Link from 'components/atoms/Link';
import { IconButton, BUTTON_SIZE_TYPE, BUTTON_KIND_TYPE } from 'components/atoms/Button';

import { useBoolean } from 'util/hook';

import { usePartner } from 'models/partner';

import HamburgerIcon from 'images/icon/hamburger.inline.svg';
import ArrowIcon from 'images/icon/dropdown-arrow.inline.svg';
import CloseIcon from 'images/icon/close.inline.svg';
import styles from './index.css';

export const MobileNavigator = ({ notInverse }) => {
	const contentRef = useRef(null);
	const [openSideMenu, { setFalse, setTrue }] = useBoolean({ defaultBoolean: false });
	const [openAboutDropdown, { toggle: toggleAboutDropdown }] = useBoolean({
		defaultBoolean: false,
	});
	const [openNewsDropdown, { toggle: toggleNewsDropdown }] = useBoolean({
		defaultBoolean: false,
	});
	const [{ partnerStatus }] = usePartner();

	const outsideClick = e => {
		if (openSideMenu && contentRef.current && !contentRef.current.contains(e.target)) {
			setFalse();
		}
	};

	return (
		<div className={styles.mobileNavigator}>
			<IconButton
				className={classnames(styles.hamburgerIcon, {
					[styles.notInverse]: notInverse,
				})}
				size={BUTTON_SIZE_TYPE.SMALL}
				kind={BUTTON_KIND_TYPE.SECONDARY}
				Icon={HamburgerIcon}
				onClick={setTrue}
			/>
			<div
				className={classnames(styles.sideMenu, {
					[styles.open]: openSideMenu,
					[styles.close]: !openSideMenu,
				})}
				onClick={outsideClick}
			>
				<div ref={contentRef} className={styles.content}>
					<button className={classnames(styles.closeIcon)} type="button" onClick={setFalse}>
						<CloseIcon />
					</button>
					<div className={styles.section}>
						<ul>
							<li>
								<div
									className={classnames(styles.outerItem, styles.groupTitle, {
										[styles.open]: openAboutDropdown,
									})}
									onClick={toggleAboutDropdown}
								>
									關於我們
									<ArrowIcon className={styles.arrow} />
								</div>
								{openAboutDropdown && (
									<ul>
										<li className={styles.innerItem}>
											<Link to="/about/origin" hash="" onClick={setFalse}>
												成立緣由
											</Link>
										</li>
										<li className={styles.innerItem}>
											<Link to="/about/location" hash="" onClick={setFalse}>
												本所位置
											</Link>
										</li>
										{partnerStatus === 'ON' && (
											<li className={styles.innerItem}>
												<Link to="/partner" hash="" onClick={setFalse}>
													策略夥伴
												</Link>
											</li>
										)}
										{/* <li className={styles.innerItem}>
											<Link to="/about/expert" hash="" onClick={setFalse}>
												諮詢專家
											</Link>
										</li> */}
									</ul>
								)}
							</li>
							<li className={styles.outerItem}>
								<Link to="/activity/posts" hash="" onClick={setFalse}>
									文章專區
								</Link>
							</li>
							<li className={styles.outerItem}>
								<Link to="/legalMap/legal-posts" hash="" onClick={setFalse}>
									法規地圖
								</Link>
							</li>
							<li>
								<div
									className={classnames(styles.outerItem, styles.groupTitle, {
										[styles.open]: openNewsDropdown,
									})}
									onClick={toggleNewsDropdown}
								>
									最新消息
									<ArrowIcon className={styles.arrow} />
								</div>
								{openNewsDropdown && (
									<ul>
										<li className={styles.innerItem}>
											<Link to="/activity/news" hash="" onClick={setFalse}>
												最新消息
											</Link>
										</li>
										<li className={styles.innerItem}>
											<Link to="/activity/events" hash="" onClick={setFalse}>
												報名活動
											</Link>
										</li>
									</ul>
								)}
							</li>
							<li className={styles.outerItem}>
								<Link to="/contact" hash="" onClick={setFalse}>
									聯絡我們
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

const Navigator = ({ notInverse }) => {
	const [{ partnerStatus }] = usePartner();
	return (
		<nav
			className={classnames(styles.navigator, {
				[styles.notInverse]: notInverse,
			})}
		>
			<ul>
				<li className={styles.group}>
					<div className={styles.groupTitle}>
						<Link to="/about/origin" hash="">
							關於我們
						</Link>
					</div>
					<div className={styles.groupWrapper}>
						<ul className={styles.groupContent}>
							<li className={styles.innerItem}>
								<Link to="/about/origin" hash="">
									成立緣由
								</Link>
							</li>
							<li className={styles.innerItem}>
								<Link to="/about/location" hash="">
									本所位置
								</Link>
							</li>
							{partnerStatus === 'ON' && (
								<li className={styles.innerItem}>
									<Link to="/partner">策略夥伴</Link>
								</li>
							)}
							{/* <li className={styles.innerItem}>
							<Link to="/about/expert" hash="">
								諮詢專家
							</Link>
						</li> */}
						</ul>
					</div>
				</li>
				<li>
					<Link to="/activity/posts">文章專區</Link>
				</li>
				<li>
					<Link to="/legalMap/legal-posts">法規地圖</Link>
				</li>
				<li className={styles.group}>
					<div className={styles.groupTitle}>
						<Link to="/activity/news" hash="">
							最新消息
						</Link>
					</div>
					<div className={styles.groupWrapper}>
						<ul className={styles.groupContent}>
							<li className={styles.innerItem}>
								<Link to="/activity/news" hash="">
									最新消息
								</Link>
							</li>
							<li className={styles.innerItem}>
								<Link to="/activity/events" hash="">
									報名活動
								</Link>
							</li>
						</ul>
					</div>
				</li>
				<li>
					<Link to="/contact">聯絡我們</Link>
				</li>
			</ul>
		</nav>
	);
};

export default Navigator;
