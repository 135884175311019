import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

const defaultData = {
	id: -1,
	img: '',
	title: '',
	partner_block: [],
	link: '',
};

export const getPartners = createAction('GET_PARTNERS', async () => {
	const result = await wrapFetch('partners', { method: 'GET' });

	if (result.message === 'Success' && result.data.data) {
		const data = {};

		const list = result.data.data.map(v => {
			data[v.id] = {
				...defaultData,
				...v,
			};

			return v.id;
		});

		return { data, list };
	}
	return { list: [], data: {} };
});

export const cleanPartners = createAction('CLEAN_PARTNERS');

export const checkPartnerStatus = createAction('CHECK_PARTNER', async () => {
	const result = await wrapFetch('partners/menuStatus', { method: 'GET' });
	if (result.message === 'Success') {
		return result.data;
	}
	return 'OFF';
});

const reducer = {
	partners: handleActions(
		{
			GET_PARTNERS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_PARTNERS_FULFILLED: (state, action) => ({
				...state,
				data: {
					...state.data,
					...action.payload.data,
				},
				list: action.payload.list,
				loading: false,
			}),

			CLEAN_PARTNERS: state => ({
				...state,
				data: {},
				list: [],
			}),

			CHECK_PARTNER_FULFILLED: (state, action) => ({
				...state,
				partnerStatus: action.payload,
			}),
		},
		{
			loading: false,
			data: {},
			list: [],
			partnerStatus: '',
		},
	),
};

const selectPartners = createSelector(
	state => state.partners.list,
	state => state.partners.data,
	state => state.partners.partnerStatus,
	(list, data, partnerStatus) => ({
		partners: list.map(id => data[id]),
		partnerStatus,
	}),
);

export const usePartner = () => useRedux(selectPartners, { getPartners, cleanPartners });

export default { reducer };
