import { createAction, handleActions } from 'redux-actions';

import { wrapFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

export const getMarquee = createAction('GET_MARQUEE', async () => {
	const result = await wrapFetch('marquee');
	if (result.message === 'Success' && result.data) {
		return { data: result.data };
	}
	return { data: [] };
});

const reducer = {
	marquee: handleActions(
		{
			GET_MARQUEE_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_MARQUEE_FULFILLED: (state, action) => ({
				...state,
				data: action.payload.data,
				loading: false,
			}),
		},
		{
			loading: false,
			data: [],
		},
	),
};

const mapHooksToState = state => ({
	marquee: state.marquee.data,
});

export const useMarquee = () => useRedux(mapHooksToState, { getMarquee });
export default { reducer };
