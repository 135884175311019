import React from 'react';

import { getEventRegistrationById, cleanRegistrationForm, modalOpen } from 'models/registration';
import { getEventInfoById } from 'models/events';

export default {
	path: '/registration',
	components: () => [import('./component')],
	render: ([Registration]) => <Registration />,
	onEnter: async ({ store, params }) => {
		console.log('on Enter Registration', store, params);
		await store.dispatch(cleanRegistrationForm());
		await store.dispatch(getEventRegistrationById(params.id));
		await store.dispatch(getEventInfoById(params.id));
		await store.dispatch(modalOpen(null));
		const {
			registration: { eventStatus },
		} = store.getState();

		// 如果活動狀態不是進行中，無法進入報名頁，導回活動列表
		if (eventStatus !== 'IN_PROGRESS') {
			window.location.href = '/activity/events';
		}

		console.log('on Enter Registration / end');
	},
};
