import React from 'react';
import { getNewsProcess, cleanNews, selectNewsByTags } from 'models/news';
import { getTags, cleanTags, addSelectedTag, TAG_TYPE } from 'models/tag';

import NewsInfoRoute from './NewsInfo';

export default {
	path: '/news',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([News]) => <News />,
			onEnter: async ({ next, store }) => {
				console.log('on Enter news', store);
				const {
					news: { selectCategoryTags },
				} = store.getState();

				store.dispatch(cleanNews());
				store.dispatch(cleanTags());

				if (selectCategoryTags.length !== 0) {
					store.dispatch(selectNewsByTags(selectCategoryTags));
					selectCategoryTags.forEach(tag => store.dispatch(addSelectedTag(tag)));
				}

				store.dispatch(getNewsProcess(1));
				store.dispatch(getTags(TAG_TYPE.NEWS));
				const children = await next();
				console.log('on Enter news / end');

				return children;
			},
		},
		NewsInfoRoute,
	],
};
