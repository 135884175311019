import React, { useState } from 'react';
import classnames from 'classnames';

import { InputSearch, INPUT_SIZE_TYPE } from 'components/atoms/Input';
import Button, { IconButton, BUTTON_SIZE_TYPE, BUTTON_KIND_TYPE } from 'components/atoms/Button';

import SearchIcon from 'images/icon/search.inline.svg';
import CloseIcon from 'images/icon/close.inline.svg';

import styles from './index.css';

const SearchInput = ({
	media,
	notInverse,
	isFocus,
	setFocus,
	onBlur,
	onFetchSearch = () => {},
}) => {
	const [value, setValue] = useState('');

	const onSubmit = () => {
		onFetchSearch(value);
		setValue('');
	};

	const onKeyDown = e => {
		if (e.keyCode !== 13) {
			return undefined;
		}
		e.preventDefault();
		return onSubmit(value);
	};

	return (
		<div className={styles.searchContainer}>
			{media !== 'desktop' && !isFocus && (
				<IconButton
					className={classnames(styles.mobileSearchIcon, {
						[styles.notInverse]: notInverse,
					})}
					size={BUTTON_SIZE_TYPE.SMALL}
					kind={BUTTON_KIND_TYPE.SECONDARY}
					Icon={SearchIcon}
					onClick={setFocus}
				/>
			)}
			{isFocus && (
				<IconButton
					className={classnames(styles.closeIcon, {
						[styles.notInverse]: notInverse,
					})}
					onClick={() => setValue('')}
					size={media === 'desktop' ? BUTTON_SIZE_TYPE.MEDIUM : BUTTON_SIZE_TYPE.SMALL}
					kind={BUTTON_KIND_TYPE.SECONDARY}
					Icon={CloseIcon}
				/>
			)}
			{(media === 'desktop' || isFocus) && (
				<InputSearch
					className={classnames(styles.inputContainer, {
						[styles.focus]: isFocus,
						[styles.notInverse]: notInverse,
					})}
					value={value}
					placeholder={media === 'desktop' ? '搜尋 stli 生醫未來式' : ''}
					onFocus={setFocus}
					onBlur={onBlur}
					onKeyDown={onKeyDown}
					onChange={e => setValue(e.target.value)}
					size={media !== 'desktop' ? INPUT_SIZE_TYPE.SMALL : ''}
				/>
			)}
			{isFocus && media === 'desktop' && (
				<Button id="search-button" size={BUTTON_SIZE_TYPE.MEDIUM} onClick={onSubmit}>
					確認
				</Button>
			)}
		</div>
	);
};

export default SearchInput;
