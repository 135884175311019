import qs from 'qs';

const { API_ENDPOINT, EDM_API_ENDPOINT } = process.env;

export const generateUrl = (url, params) => {
	const paramsString = qs.stringify(params, { arrayFormat: 'brackets' });

	const URL =
		paramsString !== ''
			? `${API_ENDPOINT}/backstage/api/v1/web/${url}?${paramsString}`
			: `${API_ENDPOINT}/backstage/api/v1/web/${url}`;

	return URL;
};

export const wrapFetch = async (url, options = { headers: {} }, params = {}) => {
	const URL = generateUrl(url, params);

	const headers = new Headers({
		'Content-Type': 'application/json',
		Accept: 'application/json',
		...options.headers,
	});

	const result = await fetch(URL, { ...options, headers });

	return result.json();
};

// export const fetchEdm = async email => {
//	const URL = `${EDM_API_ENDPOINT}/backstage/api/p/s/auth/email`;

//	const headers = new Headers({
//		'Content-Type': 'application/json',
//		Accept: 'application/json',
//	});

//	const result = await fetch(URL, { method: 'POST', body: JSON.stringify({ email }), headers });

//	return result.json();
// };

export const checkEdmStatus = async email => {
	const URL = `${EDM_API_ENDPOINT}/backstage/api/subscribe/check`;

	const headers = new Headers({
		'Content-Type': 'application/json',
		Accept: 'application/json',
	});

	const result = await fetch(URL, { method: 'POST', body: JSON.stringify({ email }), headers });

	return result.json();
};

export const sendSubscribeEmail = async (email, source) => {
	const URL = `${EDM_API_ENDPOINT}/backstage/api/subscribe/send-auth-email`;

	const headers = new Headers({
		'Content-Type': 'application/json',
		Accept: 'application/json',
	});

	const result = await fetch(URL, {
		method: 'POST',
		body: JSON.stringify({ email, source }),
		headers,
	});

	return result.json();
};

export const cancelEdm = async email => {
	const URL = `${EDM_API_ENDPOINT}/backstage/api/subscribe/cancel`;

	const headers = new Headers({
		'Content-Type': 'application/json',
		Accept: 'application/json',
	});

	const result = await fetch(URL, { method: 'PUT', body: JSON.stringify({ email }), headers });

	return result.json();
};
