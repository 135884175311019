import { createAction, handleActions } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

const defaultContactForm = {
	company: { title: '單位名稱', value: '', error: '' },
	name: { title: '姓名', value: '', error: '' },
	email: { title: 'Email', value: '', error: '' },
	message: { title: '請描述您遇到的問題或想對我們說的話', value: '', error: '' },
	personal_terms: { title: '個資條款', value: '', error: '' },
};

export const cleanContactForm = createAction('CLEAN_CONTACT_FORM');

export const updateContactForm = createAction('UPDATE_CONTACT_FORM', ({ key, value, error }) => ({
	key,
	value,
	error,
}));

export const submitContactForm = createAction(
	'SUBMIT_CONTACT_FORM',
	() => async (dispatch, getState) => {
		const {
			contact: { contactForm },
		} = getState();

		const { status, message } = await wrapFetch('contact-us', {
			method: 'POST',
			body: JSON.stringify({
				company: contactForm.company.value,
				name: contactForm.name.value,
				email: contactForm.email.value,
				message: contactForm.message.value,
			}),
		});

		if (status === 200 && message === 'Success') {
			// dispatch(modalOpen('success'));
			// eslint-disable-next-line no-alert
			alert('送出成功');
			return undefined;
		}
		if (status !== 200) {
			// dispatch(modalOpen('failed'));
			// eslint-disable-next-line no-alert
			alert('送出失敗');
			throw new Error(message);
		}
		return undefined;
	},
);

const reducer = {
	contact: handleActions(
		{
			CLEAN_CONTACT_FORM: state => ({
				...state,
				contactForm: defaultContactForm,
			}),

			UPDATE_CONTACT_FORM: (state, action) => ({
				...state,
				contactForm: {
					...state.contactForm,
					[action.payload.key]: {
						...state.contactForm[action.payload.key],
						value: action.payload.value,
						error: action.payload.error,
					},
				},
			}),
		},
		{
			contactForm: defaultContactForm,
		},
	),
};

const mapHooksToState = state => ({
	contactForm: state.contact.contactForm,
});

export const useContact = () =>
	useRedux(mapHooksToState, {
		cleanContactForm,
		updateContactForm,
		submitContactForm,
	});

export default { reducer };
