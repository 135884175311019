import React from 'react';

export default {
	path: '/search',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'search' */ './component')],
			render: ([Search]) => <Search />,
			onEnter: async () => {
				console.log('on Enter search');
				console.log('on Enter search / end');
			},
		},
	],
};
