import PostsRoute from './Posts';
import NewsRoute from './News';
import EventsRoute from './Events';

const routes = {
	path: '/activity',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [PostsRoute, NewsRoute, EventsRoute],
};

export default routes;
