import React from 'react';

import { getEventInfoById, cleanEventInfo } from 'models/events';
import RegistrationRoute from './Registration';

export default {
	path: '/:id',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([Event]) => <Event />,
			onEnter: async ({ next, store, params }) => {
				console.log('on Enter event', store, params);
				await store.dispatch(cleanEventInfo());
				await store.dispatch(getEventInfoById(params.id));
				console.log('on Enter event / end');
				const children = await next();
				return children;
			},
		},
		RegistrationRoute,
	],
};
