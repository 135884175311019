import React from 'react';

import LogoDark from 'images/icon/stli-biofuture-logo-dark.inline.svg';

import styles from './index.css';

const SubscribeHeader = () => (
	<div className={styles.headerWrapper}>
		<LogoDark />
	</div>
);

export default SubscribeHeader;
