import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { useRedux } from 'util/hook/redux';
import { wrapFetch } from 'util/api';

/**
 * 為了區分清單頁與內頁資料，定義變數名如下：
 * posts: 文章列表（清單頁）
 * postInfo: 單一篇文章（內頁）
 */

export const getPosts = createAction('GET_POSTS', async ({ page, tags }) => {
	const result = await wrapFetch(
		'articles',
		{
			method: 'GET',
		},
		{
			page,
			tags,
		},
	);

	if (result.message === 'Success' && result.data.data) {
		const data = {};

		const list = result.data.data.map(v => {
			data[v.id] = {
				id: v.id,
				image: v.img,
				title: v.title || '',
				date: v.article_date,
				author: v.author,
			};

			return v.id;
		});

		return {
			list,
			data,
			meta: {
				currentPage: result.data.meta.current_page,
				from: result.data.meta.from,
				lastPage: result.data.meta.last_page,
				perPage: result.data.meta.per_page,
				to: result.data.meta.to,
				total: result.data.meta.total,
			},
		};
	}
	return { list: [], data: {}, meta: {} };
});

export const selectPostsByTags = createAction('SELECT_POSTS_BY_TAGS', tags => tags);

export const cleanPosts = createAction('CLEAN_POSTS');

export const getPostsProcess = createAction(
	'GET_POSTS_PROCESS',
	page => async (dispatch, getState) => {
		let queryPage = page;

		if (typeof queryPage === 'undefined') {
			const {
				posts: { meta },
			} = getState();

			queryPage = meta.currentPage;
		}

		const {
			posts: { selectCategoryTags },
		} = getState();

		await dispatch(
			getPosts({
				page: queryPage,
				tags: selectCategoryTags.toString(),
			}),
		);
	},
);

const defaultPostInfo = {
	id: -1,
	article_date: '',
	author: '',
	title: '',
	subtitle: '',
	tags: [],
	img: '',
	summary: '',
	content: '',
	related_article: '',
	article_resource: '',
	article_file: [],
	last_id: -1,
	next_id: -1,
};

export const getPostInfoById = createAction('GET_POST_BY_ID', async id => {
	const result = await wrapFetch(`article/${id}`, { method: 'GET' });

	if (result.message === 'Success' && result.data.data) {
		return { ...defaultPostInfo, ...result.data.data };
	}
	return {};
});

export const cleanPostInfo = createAction('CLEAN_POST');

const reducer = {
	posts: handleActions(
		{
			GET_POSTS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_POSTS_FULFILLED: (state, action) => ({
				...state,
				data: {
					...state.data,
					...action.payload.data,
				},
				meta: {
					...state.meta,
					...action.payload.meta,
				},
				list: action.payload.list,
				loading: false,
			}),

			SELECT_POSTS_BY_TAGS: (state, action) => ({
				...state,
				selectCategoryTags: action.payload,
			}),

			GET_POST_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_POST_BY_ID_FULFILLED: (state, action) => ({
				...state,
				postInfo: action.payload,
				loading: false,
			}),

			CLEAN_POSTS: state => ({
				...state,
				data: {},
				list: [],
				selectCategoryTags: [],
			}),

			CLEAN_POST: state => ({
				...state,
				postInfo: {},
			}),
		},
		{
			loading: false,
			data: {},
			list: [],
			meta: {
				currentPage: 0,
				from: 0,
				lastPage: 0,
				perPage: 0,
				to: 0,
				total: 0,
			},
			selectPostsByTags: [],
			selectCategoryTags: [],
			search: '',
			postInfo: {},
		},
	),
};

const selectPosts = createSelector(
	state => state.posts.list,
	state => state.posts.data,
	state => state.posts.meta,
	(list, data, meta) => ({
		posts: list.map(id => data[id]),
		meta,
	}),
);

export const usePost = () => useRedux(selectPosts, { cleanPosts });

const selectPostsFilter = createSelector(
	state => state.posts.selectCategoryTags,
	list => ({
		tags: list,
	}),
);

export const usePostsFilter = () =>
	useRedux(selectPostsFilter, { getPostsProcess, selectPostsByTags });

const selectPostInfo = state => ({
	postInfo: state.posts.postInfo,
});

export const usePostInfo = () => useRedux(selectPostInfo, { getPostInfoById });

export default { reducer };
